import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import './privacy.css'; // Ensure the correct path for the CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      {/* Consistent Navigation Bar */}
      <Navbar />

      {/* Privacy Policy Content */}
      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p className="last-updated"><strong>Last Updated: 3/7/2025</strong></p>
        
        {/* App Details to comply with Google Play requirements */}
        <p className="app-details" style={{ fontSize: '18px', textAlign: 'center', marginTop: '10px' }}>
          This Privacy Policy applies to the <strong>Toro Livestock Management</strong> app (com.braedentaylor.Toro), developed and maintained by Toro Livestock Management.
        </p>

        {/* Privacy Policy Sections */}
        <section className="policy-section">
          <h2>1. Information We Collect</h2>
          <ul>
            <li>
              <strong>Personal Information:</strong> We collect information such as your name, email address, and payment details when you create an account or subscribe to our services.
            </li>
            <li>
              <strong>Usage Data:</strong> We collect data about how you interact with our website and app, including IP addresses, browser types, and device information.
            </li>
          </ul>
        </section>

        <section className="policy-section">
          <h2>2. How We Use Your Information</h2>
          <ul>
            <li>Provide, maintain, and improve our Services.</li>
            <li>Process transactions and manage billing.</li>
            <li>Communicate with you regarding updates, promotions, and support.</li>
            <li>Ensure the security and integrity of our Services.</li>
          </ul>
        </section>

        <section className="policy-section">
          <h2>3. Sharing Your Information</h2>
          <p>
            We do not sell your personal information. We may share your data with third-party service providers (e.g., payment processors) to facilitate our Services. These third parties are bound by strict confidentiality agreements.
          </p>
        </section>

        <section className="policy-section">
          <h2>4. Cookies and Tracking Technologies</h2>
          <p>
            We use cookies to enhance your experience and gather usage data. You can control cookie preferences through your browser settings.
          </p>
        </section>

        <section className="policy-section">
          <h2>5. Your Rights</h2>
          <ul>
            <li>Access the personal data we hold about you.</li>
            <li>Request corrections or updates to your information.</li>
            <li>Request deletion of your data, subject to certain legal obligations.</li>
          </ul>
        </section>

        <section className="policy-section">
          <h2>6. Data Security</h2>
          <p>
            We implement reasonable security measures to protect your information from unauthorized access. However, no method of transmission over the internet is 100% secure.
          </p>
        </section>

        <section className="policy-section">
          <h2>7. Children’s Privacy</h2>
          <p>
            Our Services are not directed to individuals under the age of 18. We do not knowingly collect personal information from minors.
          </p>
        </section>

        <section className="policy-section">
          <h2>8. Changes to this Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Significant changes will be communicated to users, and continued use of our Services indicates your acceptance of the updated policy.
          </p>
        </section>

        <section className="policy-section">
          <h2>9. Contact Us</h2>
          <p>
            For questions or concerns, please contact us at <strong>info@torolm.com</strong>.
          </p>
        </section>
        
        {/* Account Deletion Request Link */}
        <section className="policy-section">
          <h2>10. Account Deletion Request</h2>
          <p>
            If you wish to request that your account and all associated data be deleted, please visit our&nbsp;
            <Link to="/account-deletion-request">
              Account Deletion Request page
            </Link>.
          </p>
          <p>
            This link will guide you through the steps required to delete your account and provides details about which data will be removed or retained (up to 90 days for compliance purposes).
          </p>
        </section>
      </div>

      {/* Consistent Footer */}
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
