import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import './PricingPage.css';

const PricingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="pricing-page">
      <Navbar />

      <div className="pricing-container">
        {/* Page Title */}
        <div className="page-title">
          <h1>Choose Your Plan</h1>
        </div>

        {/* Pricing Cards */}
        <div className="pricing-plans">
          <div className="pricing-card" onClick={() => navigate('/signup')}>
            <h2>Basic Membership</h2>
            <p className="price-label">Pricing Options:</p>
            <p className="monthly-price"><strong>Monthly: $9.99/month</strong></p>
            <p className="yearly-price"><strong>Yearly: $99.99/year</strong> (16% discount!)</p>
            <ul className="pricing-features">
              <li>
                Core Livestock Management:
                <ul>
                  <li>Livestock Record Management</li>
                  <li>Basic Health &amp; Breeding Tracking</li>
                  <li>Weight Monitoring &amp; Basic Visualization</li>
                </ul>
              </li>
              <li>CSV Import</li>
              <li>Basic Search &amp; Filtering</li>
              <li>Mobile-First Experience</li>
            </ul>
          </div>
          <div className="pricing-card" onClick={() => navigate('/signup')}>
            <h2>Pro Membership</h2>
            <p className="price-label">Pricing Options:</p>
            <p className="monthly-price"><strong>Monthly: $19.99/month</strong></p>
            <p className="yearly-price"><strong>Yearly: $199.99/year</strong> (16% discount!)</p>
            <ul className="pricing-features">
              <li>
                Everything in Basic plus Enhanced Data Management:
                <ul>
                  <li>CSV Export</li>
                  <li>Advanced Filtering &amp; Saved Filters</li>
                  <li>Custom Column Reordering</li>
                </ul>
              </li>
              <li>Advanced Reporting &amp; Data Visualization</li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PricingPage;
