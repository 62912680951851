import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './GetAppPage.css';

// Import the images
import appleStoreIcon from '../../assets/apple_store_icon.png';
import googlePlayIcon from '../../assets/google_play_icon.png';

const GetAppPage = () => {
  return (
    <div className="getapp-page">
      {/* Pass props to render transparent backgrounds and hide the Get the App button */}
      <Navbar transparent={true} hideGetAppButton={true} />

      {/* Main Content */}
      <div className="content-container">
        <h1 className="page-title">Get the App</h1>
        
        <div className="download-links">
          <a
            href="https://apps.apple.com/app/6723902761"
            className="download-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img 
              src={appleStoreIcon} 
              alt="App Store" 
              className="store-icon"
            />
            <span>Download on the App Store</span>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.braedentaylor.Toro"
            className="download-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img 
              src={googlePlayIcon} 
              alt="Google Play" 
              className="store-icon"
            />
            <span>Get it on Google Play</span>
          </a>
        </div>
      </div>

      <Footer transparent={true} />
    </div>
  );
};

export default GetAppPage;