import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import './ProductsPage.css';

const ProductsPage = () => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate('/signup'); // Navigates to the sign-up page when a card is clicked
  };

  return (
    <div className="products-page">
      <Navbar />

      {/* Pricing Container with grey background */}
      <div className="pricing-container">
        {/* Page Title */}
        <div className="page-title">
          <h1>Toro Products</h1>
          <p>Empowering Ranchers with Effortless Livestock Management</p>
        </div>

        {/* Tiered Plans */}
        <div className="pricing-section">
          <div className="pricing-plan" onClick={handleCardClick}>
            <h2>Basic Membership</h2>
            <ul>
              <li>
                Core Livestock Management
                <ul>
                  <li>Livestock Record Management</li>
                  <li>Basic Health &amp; Breeding Tracking</li>
                  <li>Weight Monitoring &amp; Basic Visualization</li>
                </ul>
              </li>
              <li>CSV Import</li>
              <li>Basic Search &amp; Filtering</li>
              <li>Mobile-First Experience</li>
            </ul>
          </div>
          <div className="pricing-plan" onClick={handleCardClick}>
            <h2>Pro Membership</h2>
            <ul>
              <li>Everything in Basic</li>
              <li>
                Enhanced Data Management
                <ul>
                  <li>CSV Export</li>
                  <li>Advanced Filtering &amp; Saved Filters</li>
                  <li>Custom Column Reordering</li>
                </ul>
              </li>
              <li>Advanced Reporting &amp; Data Visualization</li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProductsPage;
