import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import './accountDeletion.css';

const AccountDeletionRequest = () => {
  const [formData, setFormData] = useState({
    email: '',
    accountId: '',
    reason: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Replace with your actual deletion request endpoint
      const response = await axios.post('https://api.torolm.com/account-deletion-request', formData);
      alert(response.data.message);
      setFormData({ email: '', accountId: '', reason: '' });
    } catch (error) {
      alert(error.response?.data?.error || 'An error occurred while processing your request.');
    }
  };

  return (
    <div className="account-deletion-container">
      <Navbar />
      <div className="account-deletion-content">
        
       <h1>Account Deletion Request</h1>
       {/* Display your app/developer name as listed in Google Play */}
       <h2 className="app-name">Toro Livestock Management</h2>
        <p>
          If you wish to delete your account and all associated data, please complete the form below.
          Your account information—including your email address, username, and any other data provided during registration—will be permanently removed.
        </p>
        <p>
          <strong>Important:</strong>
        </p>
        <ul>
          <li>
            Our app supports account creation via Username, Email and Password.
          </li>
          <li>
            Once your account is deleted, this action cannot be undone. For security and compliance purposes, some data may be retained for up to 90 days.
          </li>
        </ul>
        <p>
          Please ensure you have backed up any important information before submitting your request.
        </p>
        <form className="account-deletion-form" onSubmit={handleSubmit}>
          <label htmlFor="email">Registered Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="accountId">Account ID (if applicable):</label>
          <input
            type="text"
            id="accountId"
            name="accountId"
            value={formData.accountId}
            onChange={handleChange}
          />

          <label htmlFor="reason">Reason for Deletion (optional):</label>
          <textarea
            id="reason"
            name="reason"
            rows="5"
            value={formData.reason}
            onChange={handleChange}
          />

          <button type="submit" className="account-deletion-submit">
            Submit Deletion Request
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default AccountDeletionRequest;
